import 'svgxuse';
import 'babel-polyfill';

import feedback from './modules/feedback';

if ('visibilityState' in document) {

  feedback();

}
