export default () => {
  const inputs = document.querySelectorAll('input, select, textarea');

  [ ...inputs ].forEach(input => {
    if (input.type === 'hidden') return;
    if (input.value) input.parentNode.classList.add('filled');
    input.addEventListener('focus', () => input.parentNode.classList.add('focus'));

    input.addEventListener('blur', () => {
      input.parentNode.classList.remove('focus');

      if (input.value) {
        input.parentNode.classList.add('filled');
      } else {
        input.parentNode.classList.remove('filled');
      }
    });
  });
};
